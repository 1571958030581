<template>
  <div class="container" style="display: flex;">
    <!-- <div style="width:20vw">
      <el-tree :data="list_data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div> -->
    <div style="width:95vw">
      <div class="search">
        <el-row>
          <el-col :span="6">
            <span v-if="flag">所属公司 : </span>
            <el-select v-model="company" placeholder="请选择" @change="getcorporation" v-if="flag">
              <el-option v-for="item in companyOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <span v-if="flags">所属项目 : </span>
            <el-select v-model="corporation" placeholder="请选择" v-if="flags" @change="getUser">
              <el-option v-for="item in corporationOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6"></el-col>
          <el-col :span="6"></el-col>
        </el-row>
      </div>
      <div class="main">
        <el-table :data="tableData" style="width: 100%" border height="800" header-align="center">
          <el-table-column prop="name" label="用户名" align="center"> </el-table-column>
          <el-table-column prop="companys" label="所属公司" align="center"> </el-table-column>
          <el-table-column prop="corporations" label="所属项目" align="center">

          </el-table-column>
          <el-table-column prop="position" label="部门" align="center"> </el-table-column>
          <el-table-column prop="rank" label="权限" align="center"> </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
          <el-table-column label="操作" style="width: 400px" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="openAddUserDialog(scope.row)">编辑</el-button>
              <el-button v-if="scope.row.uid != bendiuid" type="danger" @click="deleteUser(scope.row)">删除</el-button>
              <div v-if="scope.row.weixin_oppid == null">
                <el-button type="text" @click="bangding(scope.row.uid)">绑定微信昵称</el-button>
              </div>
              <div v-else>
                <el-button type="text" disabled>已绑定</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="微信号昵称" :visible.sync="binds" width="30%" v-loading="loading2" element-loading-text="玩命绑定中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <span>输入微信昵称：</span> <el-input placeholder="请输入相对应的微信昵称" v-model="weixinname" clearable style="width: 300px;">
          </el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="binds = false">取 消</el-button>
            <el-button type="primary" @click="bind">确 定</el-button>
          </span>
        </el-dialog>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

      <el-dialog title="修改人员信息" :visible.sync="editUserDialogVisible" width="40%">
        <el-form ref="editFormData" :model="editFormData" label-width="80px" :rules="editFormDataRules">
          <el-form-item label="用户名" prop="name">
            <el-input v-model="editFormData.name"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="editFormData.password"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="editFormData.phone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="用户等级" prop="rank">
          <el-select v-model="editFormData.rank" placeholder="请选择">
            <el-option
              v-for="item in rankOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
          <el-form-item label="职位" prop="position">
            <el-input v-model="editFormData.position"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editUser">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  addUserInfo,
  getFirm,
  getProjectInfo,
  getUserInfo,
  editUserInfo,
  deleteUserInfo,
  weixin_opid,
  Personnel_post,
  Personnel_get,
  Personnel_post1
} from "../../../request/project";
import {
  personnelInformation,
  relationship
} from "../../../request/risk";
export default {
  data() {
    return {
      states: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      list_data: [],
      data_list: [],
      activeNames: '',
      bendiuid: 0,
      loading2: false,
      user_id: '',
      weixinname: '',
      binds: false,
      company: "", //公司下拉框
      corporation: "", //项目下拉框
      editUserDialogVisible: false,

      editFormData: {
        name: "",
        password: "",
        phone: "",
        rank: "",
        position: "",
        corporation: "",
        company: "",
      },
      rankOptions: [
        {
          value: 0,
          label: "系统管理员",
        },
        {
          value: 1,
          label: "公司管理员",
        },
        {
          value: 2,
          label: "项目管理员",
        },
        {
          value: 3,
          label: "普通员工",
        },
      ],
      editFormDataRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "change" }],
        position: [
          { required: true, message: "请输入职位", trigger: "change" },
        ],
        company: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      companyOptions: [],
      corporationOptions: [],
      flag: false,
      flags: false,
      tableData: [],
      total: 0,//分页总数
      page: 1,
      pageSize: 10,
      pjtid: '',

    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {

      if (localStorage.getItem('type') == 'pro') {
        this.Personnel_get(localStorage.getItem('project'))
      } else if (localStorage.getItem('type') == 'firm') {
        this.Personnel_post(localStorage.getItem('project'))
      }
      // 获取所有公司
      // this.getcompany();
      this.bendiuid = localStorage.getItem('uid')
      console.log(this.bendiuid)
    },
  },

  created() {

  },
  mounted() {
    // this.relationship()
    // this.personnelInformation()
    if (localStorage.getItem('rank') == '0') {
      this.Personnel_post1()
    } else {
      if (localStorage.getItem('type') == 'pro') {
        this.Personnel_get(localStorage.getItem('project'))
      } else if (localStorage.getItem('type') == 'firm') {
        this.Personnel_post(localStorage.getItem('project'))
      }
    }



    // 获取所有公司
    // this.getcompany();
    this.bendiuid = localStorage.getItem('uid')
    console.log(this.bendiuid)

  },
  methods: {
    // handleNodeClick(data) {
    //   console.log(data);
    //   this.states = data
    //   if (data.type == "firm") {
    //     this.Personnel_post(data.id)
    //   } else if (data.type == "pro") {
    //     this.Personnel_get(data.id)
    //   }
    // },

    async Personnel_post(id) {
      let msg = {
        page: this.page,
        size: this.pageSize,
        firm_id: id
      }
      const res = await Personnel_post(msg)
      console.log(res, 'Personnel_post');
      this.tableData = res.data.data;
      this.total = res.data.count

    },
    async Personnel_get(id) {
      let msg = {
        page: this.page,
        size: this.pageSize,
        pro_id: id
      }
      const res = await Personnel_get(msg)
      console.log(res, 'Personnel_get');
      this.tableData = res.data.data;
      this.total = res.data.count
    },

    async Personnel_post1() {
      let msg = {
        page: this.page,
        size: this.pageSize,
      }
      const res = await Personnel_post1(msg)
      console.log(res, 'Personnel_post');
      this.tableData = res.data.data;
      this.total = res.data.count
    },




    // async relationship() {
    //   let msg = {
    //     User_identity: localStorage.getItem("uid")
    //   }
    //   const res = await relationship(msg)
    //   console.log(res, '121111');
    //   this.list_data = res.data.data

    //   this.states = this.list_data[0]
    //   if (this.list_data[0].type == "firm") {
    //     this.Personnel_post(this.list_data[0].id)
    //   } else if (this.list_data[0].type == "pro") {
    //     this.Personnel_get(this.list_data[0].id)
    //   }
    // },

    // 获取所有项目
    bangding(e) {
      this.binds = true
      this.user_id = e
    },
    async bind() {
      console.log(this.user_id, this.weixinname)
      var msg = {
        id: this.user_id,
        name: this.weixinname
      }
      this.loading2 = true
      const res = await weixin_opid(msg);
      console.log(res)
      if (res.data.code == 200) {
        this.$message({
          message: '恭喜你，绑定成功',
          type: 'success'
        });

        this.loading2 = false
        this.binds = false
        // this.getProject();
        if (this.states.type == "firm") {
          this.Personnel_post(this.states.id)
        } else if (this.states.type == "pro") {
          this.Personnel_get(this.states.id)
        }

      } else {

        this.loading2 = false
        this.binds = false
        // this.getProject();
        this.$message.error('绑定失败，请检查是否关注公众号或者拼写正确');
      }

    },
    async getProject() {
      let rank = window.localStorage.getItem("rank");
      if (rank == 0) {
        // 超级管理员
        this.flag = true;
        this.flags = true;
        // 超级管理员获取所有公司和项目
        this.getcompany();
      } else {
        let msg = {
          corporation: this.activeNames,
          page: this.page,
          size: this.pageSize,
        };
        const res = await getUserInfo(msg);
        console.log(res);
        if (res.data.length == 0) {
          return;
        }
        this.tableData = res.data.data;
        this.total = res.data.count
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData.rank == 0) {
            this.tableData.rank = "超级管理员";
          } else if (this.tableData.rank == 1) {
            this.tableData.rank = "公司管理员";
          } else if (this.tableData.rank == 2) {
            this.tableData.rank = "项目管理员";
          } else if (this.tableData.rank == 2) {
            this.tableData.rank = "普通用户";
          }
        }
      }
    },
    // 获取选中项目下的所有人员
    async getUser() {
      this.tableData = [];
      let msg = {
        // corporation: this.corporation,
        corporation: localStorage.getItem('corporations'),
      };
      const res = await getUserInfo(msg);
      console.log(res);
      if (res.data.length == 0) {
        return;
      }
      this.tableData = res.data;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].rank == 0) {
          this.tableData[i].rank = "超级管理员";
        } else if (this.tableData[i].rank == 1) {
          this.tableData[i].rank = "公司管理员";
        } else if (this.tableData[i].rank == 2) {
          this.tableData[i].rank = "项目管理员";
        } else if (this.tableData[i].rank == 3) {
          this.tableData[i].rank = "普通用户";
        }
      }
    },
    // 获取所有公司
    async getcompany() {
      const { data: res } = await getFirm();
      //  console.log(res);
      for (let i = 0; i < res.length; i++) {
        let obj = {};
        obj.value = res[i].id;
        obj.label = res[i].name;
        this.companyOptions.push(obj);
      }
      this.company = this.companyOptions[0].value;
      this.getcorporation();
      // console.log(this.companyOptions);
    },
    // 打开修改用户信息对话框
    openAddUserDialog(row) {
      this.editUserDialogVisible = true;
      this.editFormData = JSON.parse(JSON.stringify(row));
      this.editFormData.rank -= 0;
      //  this.editFormData= JSON.parse(JSON.stringify(this.editFormData).replace(/uid/g,"id"));
      if (localStorage.getItem("rank") == 0) {
        this.rankOptions = [
          {
            value: 0,
            label: "系统管理员",
          },
          {
            value: 1,
            label: "公司管理员",
          },
          {
            value: 2,
            label: "项目管理员",
          },
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else if (localStorage.getItem("rank") == 1) {
        this.rankOptions = [

          {
            value: 2,
            label: "项目管理员",
          },
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else if (localStorage.getItem("rank") == 2) {
        this.rankOptions = [
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else {
        this.rankOptions = [

        ];
      }
    },
    // 获取选中公司的项目
    async getcorporation() {
      this.tableData = [];
      this.corporationOptions = [];
      this.corporation = "";
      if (window.localStorage.getItem("rank") == 0) {
        // 如果是超级管理员,从选中的公司获取项目
        let msg = {
          corporation: this.flag ? window.localStorage.getItem("corporations") : window.localStorage.getItem("companys"),
          pro_id: this.flag ? 1 : 0,
          page: this.page,
          size: this.pageSize,
          name: "",
          types: "",
        };
        const { data: res } = await getProjectInfo(msg);
        if (res.length == 0) {
          return;
        }
        console.log(res);
        for (let i = 0; i < res.data.length; i++) {
          let obj = {};
          obj.value = res.data[i].id;
          obj.label = res.data[i].name;
          this.corporationOptions.push(obj);
        }

        this.corporation = this.corporationOptions[0].value;
        // console.log(res);
        this.getUser();
      } else if (window.localStorage.getItem("rank") == 1) {
        // 如果是公司管理员,从localstroge拿公司id获取项目
        let msg = {
          corporation: window.localStorage.getItem("companys"),
        };
        const { data: res } = await getProjectInfo(msg);
        if (res.length == 0) {
          return;
        }
        for (let i = 0; i < res.length; i++) {
          let obj = {};
          obj.value = res[i].id;
          obj.label = res[i].name;
          this.corporationOptions.push(obj);
        }

        this.corporation = this.corporationOptions[0].value;
        // console.log(res);
        this.getUser();
      }
    },
    // 修改用户信息
    async editUser() {
      const res = await editUserInfo(this.editFormData);
      if (res.data.code == 200) {
        this.editUserDialogVisible = false;

        if (this.states.type == "firm") {
          this.Personnel_post(this.states.id)
        } else if (this.states.type == "pro") {
          this.Personnel_get(this.states.id)
        }


        this.$message({
          message: "修改成功",
          type: "success",
        });
        return;
      }
      this.$message({
        message: "修改失败",
        type: "error",
      });
    },
    // 删除用户
    deleteUser(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let msg = {
            id: row.uid,
            uid: localStorage.getItem('uid')
          };
          const res = await deleteUserInfo(msg);
          console.log(res);
          if (res.data.code == 200) {
            this.editUserDialogVisible = false;
            if (this.states.type == "firm") {
              this.Personnel_post(this.states.id)
            } else if (this.states.type == "pro") {
              this.Personnel_get(this.states.id)
            }
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "info",
              message: res.data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 一页显示几条改变 
    handleSizeChange(val) {
      this.pageSize = val;
      if (localStorage.getItem('rank') == '0') {
      this.Personnel_post1()
    } else {
      if (localStorage.getItem('type') == 'pro') {
        this.Personnel_get(localStorage.getItem('project'))
      } else if (localStorage.getItem('type') == 'firm') {
        this.Personnel_post(localStorage.getItem('project'))
      }
    }
    },
    // 页码改变 
    handleCurrentChange(val) {
      this.page = val;
      if (localStorage.getItem('rank') == '0') {
      this.Personnel_post1()
    } else {
      if (localStorage.getItem('type') == 'pro') {
        this.Personnel_get(localStorage.getItem('project'))
      } else if (localStorage.getItem('type') == 'firm') {
        this.Personnel_post(localStorage.getItem('project'))
      }
    }

    },
  },
};
</script>
<style scoped></style>